<template>
  <div
    @click.self="closeFilterSideBar()"
    class="fixed lg:hidden w-full h-screen ober bg-dark-blue/70 top-0 right-0 bottom-0 left-0 z-[4000000] ease-in-out duration-700"
    :class="isVisible ? 'translate-x-0' : '-translate-x-full '"
  >
    <div
      class="relative bg-white max-w-[325px] h-[100dvh] overflow-scroll isolate"
    >
      <div
        class="sticky top-0 flex item-center justify-between py-3 px-4 z-50 bg-inherit"
      >
        <div
          class="flex items-center text-lg gap-2 text-secondary font-semibold"
        >
          <AdjustmentsIcon class="w-10 h-10" />
          <span>{{ $t("filter.sort") }}</span>
        </div>
        <button
          @click="closeFilterSideBar()"
          class="bg-[#D8D8D8] w-8 h-8 flex justify-center items-center rounded-full"
        >
          <XIcon class="w-6 h-6 text-secondary" />
        </button>
      </div>
       <div class="relative">
        <ProductFilterItem
          :name="`${$t('filter.sortBy')}`"
          index-of="sort"
          :items="sortOptions"
          :is-active="true"
        />
         <ProductFilterSidebar :contentFilters="filters"> </ProductFilterSidebar>
      </div> 

      <div
        class="sticky bottom-0 flex flex-row gap-2 items-center justify-center z-[100] bg-inherit p-1"
      >
        <button @click="clearAll()" class="base-outline-btn w-32 font-medium">
          {{ $t("filter.clearAll") }}
        </button>
        <BaseButton
          @click="showResults()"
          :text="`Show ${resultsNumber > 0 ? resultsNumber : ''} results`"
          class="w-40 h-11 font-medium"
        />
      </div>
    </div>
  </div>
</template>
<script setup>
import { XIcon, AdjustmentsIcon } from "@heroicons/vue/outline";
import { useProductStore } from "~~/stores/filter";
const props = defineProps({
  isVisible: {
    type: Boolean,
    default: false,
  },
  filters: Object,
  resultsNumber: Number,
});

const route = useRoute();
const path = route.path.split("/").splice(3, 1);
const path2 = route.path.split("/").splice(2, 1);

const filterStore = useProductStore();
const sortOptions = computed(() => filterStore.getSortOptions);
const clearAll = () => {
  emit("onSideBarClose");
  return filterStore.clearAllFilters();
};
const emit = defineEmits(["onSideBarClose"]);
const closeFilterSideBar = () => {
  emit("onSideBarClose");
};
const showResults = () => {
  emit("onSideBarClose");
};
</script>
