<template>
  <div>
    <div class="relative w-full mb-4">
      <BaseVSearchTextInput
      name="infection-name"
      :label="`${$t('filter.infectionSearch')}`"
      v-model="searchValue" />
      
      <button
        class="flex justify-center items-center bg-secondary 
        text-white w-8 h-8 rounded-md absolute right-1 top-1 z-30"
      >
        <SearchIcon class="w-6 h-6" />
      </button>
    </div>
    <div
      v-if="filtredInfections.length > 0"
      class="space-y-6 infection-items relative"
    >
      <div
        v-for="infection in filtredInfections"
        :key="infection.title"
        class="items-center flex"
      >
        <ProductFilterOptions
          :itemCategory="infection"
          indexOf="infection"
        ></ProductFilterOptions>
      </div>
    </div>
    <div v-else class="text-sm text-center">
      <span>{{ $t("common.nothingFound") }}</span>
    </div>
  </div>
</template>

<script setup>
import { SearchIcon } from "@heroicons/vue/outline";
const props = defineProps({
  infections: {
    type: Array,
  },
});


const searchValue = ref("");

const filtredInfections = computed(() => {
  let tempInfections = props.infections;
  if (searchValue.value != "" && searchValue.value) {
    tempInfections = tempInfections.filter((item) => {
      return item.title.toUpperCase().includes(searchValue.value.toUpperCase());
    });
  }
  return tempInfections;
});
</script>
