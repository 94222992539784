<template>
  <div class="bg-white border rounded border-gray-400 w-5 h-5 flex justify-center items-center">
    <svg class="text-primary hidden peer-checked:block" id="check" xmlns="http://www.w3.org/2000/svg" width="10"
      height="7.5" viewBox="0 0 10 7.5">
      <path id="Path_179" data-name="Path 179"
        d="M8.578,1.244,3.333,6.488,1.423,4.578A.833.833,0,1,0,.244,5.756l2.5,2.5a.833.833,0,0,0,1.178,0L9.756,2.423A.833.833,0,1,0,8.577,1.244Z"
        transform="translate(0 -1)" fill="#267476" />
    </svg>
  </div>
</template>
