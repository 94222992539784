<template>
  <div class="field relative" @click="focus">
    <VField
      :name="name"
      v-slot="{ field: { value, ...fieldProps }, meta, errors }"
    >
      <input
        ref="input"
        :id="id"
        v-model="modelValueUpdated"
        v-bind="fieldProps"
        class="base-textinput peer placeholder-transparent z-10  "
        :class="{
          ' bg-secondary/20': !meta.valid && meta.touched,
          'focus:placeholder-slate-400 px-3 placeholder': placeholder,
          'md:h-[40px] h-[50px]': props.name != 'infection-name'
        }"
        :placeholder="placeholder ? placeholder : label"
        :type="type"
        v-maska
        :data-maska="inputMask"
        :disabled="disabled"
      />
    
      <label v-if="label" class="base-textsearchinput-label" :class="{'peer-placeholder-shown:top-[10px]':props.name == 'infection-name'}">
        {{ label }}
      </label>
      <VErrorMessage
        :name="name"
        as="div"
        class="md:mt-1 mb-2 -mt-3 text-xs text-red-500 px-2"
      />
      <div class="debug" v-if="debug">
        <pre>{{ errors }}</pre>
        <pre>{{ meta }}</pre>
      </div>
    </VField>
  </div>
</template>

<script setup>
import { vMaska } from "maska";

const input = ref(null);
//const modelValue = ref(null);
const props = defineProps({
  id: String,
  type: {
    type: String,
    default: "text",
  },
  modelValue: {
    type: String,
    default: "",
  },
  name: {
    type: String,
    required: true,
  },
  label: {
    type: String,
  },
  placeholder: {
    type: String,
    default: "",
  },
  debug: {
    type: Boolean,
    default: false,
  },
  inputClass: {
    type: String,
    default: "",
  },
  inputMask: {
    type: String,
    default: "",
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  defaultValue: {
    type: String,
    default: undefined,
  },
});


function focus() {
  input.value.focus();
}
const emit = defineEmits(["update:modelValue"]);
const modelValueUpdated = computed({
  get: () => props.modelValue,
  set: (value) => {
    emit("update:modelValue", value);
  },
});
const isPassword = computed(() => props.type === "password");
</script>

<style scoped>
input:invalid {
  color: #931363;
}

[type="date"]::-webkit-inner-spin-button {
  display: none;
}
[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
}
</style>
