<template>
  <div class="max-h-screen">
    <div class="relative max-w-[326px] w-full">
      <button v-show="indexOf == 'service'" class="absolute left-32 top-[28px]" @click="openServiceModal()">
        <NuxtImg loading="lazy" src="/icons/c-info-e.svg" alt="info" class="hidden md:block ABS" width="16"
          height="16" />
      </button>
      <div @click="open"
        class="border-t-2 border-gray-200 py-5 px-4 flex justify-between items-center cursor-pointer select-none">
        <div class="flex space-x-3 items-center relative">
          <span class="text-[18px] font-semibold">{{ name }}</span>
        
          <div v-if="indexOf == 'category' && filterStore['subcategory'].length !== 0" v-html="filterStore['subcategory'].length + filterStore['category'].length"
            class="bg-secondary w-5 h-5 z-20 rounded-full flex justify-center items-center text-white text-[9px]" />

          <div v-else-if="indexOf !== 'sort' && filterStore[indexOf].length !== 0" v-html="filterStore[indexOf].length"
            class="bg-secondary w-5 h-5 z-20 rounded-full flex justify-center items-center text-white text-[9px]" />
        </div>
        <!-- <div v-if="indexOf === 'infections'" class="ml-auto flex items-center">
          <span class="mx-2 text-xs">Reverse Selection</span>
          <BaseButtonToggle
            buttonSize="small"
            :tabValue="toggleValue"
            @onChange="onTabChange"
          ></BaseButtonToggle>
        </div> -->
        <ChevronDownIcon v-if="!showOptionFilter" class="w-5 h-5" />
        <ChevronUpIcon v-else class="w-5 h-5" />
      </div>
    </div>
    <div :class="showOptionFilter ? 'p-4 py-6 h-auto' : ' max-h-0 px-4 overflow-hidden'
        " class="bg-[#F7F7F7] transition-all duration-100 ease-linear">
      <!-- SORT FILTER  -->

      <div v-if="indexOf === 'sort'" class="flex flex-col space-y-6">
        <div v-for="sort in Items" :key="sort.value" class="items-center flex">
          <div class="filter flex items-center">
            <input name="sortOptions" type="checkbox" :id="sort.text"
              :checked="sort.value == filterStore.selectedSortOption" @change="selectedSort(sort)"
              class="opacity-0 absolute w-5 h-5 peer cursor-pointer" />
            <ProductCheckbox></ProductCheckbox>
            <label :for="sort.text" class="select-none text-[14px] cursor-pointer peer-checked:font-bold px-2">
              {{ sort.text }}
            </label>
          </div>
        </div>
      </div>
      <!-- CATEGORY FILTER  -->
      <div v-if="indexOf === 'category'" class="flex flex-col space-y-6">
        <div v-for="(category, index) in Items" :key="index" class="items-center flex flex-wrap justify-between w-full">
          <ProductFilterOptions :itemCategory="category" indexOf="category"></ProductFilterOptions>
          <div v-if="category.subcategories" @click="showSubItem = !showSubItem">
            <ChevronDownIcon v-if="!showSubItem" class="w-5 h-5" />
            <ChevronUpIcon v-else class="w-5 h-5" />
          </div>
          <div v-if="showSubItem && category.subcategories" class="w-full flex flex-col space-y-6">
            <div class="mx-7 first:mt-4" v-for="subcategory in category.subcategories" :key="subcategory.id">
              <ProductFilterOptions :parentCategory="category" :itemCategory="subcategory" indexOf="subcategory">
              </ProductFilterOptions>
            </div>
          </div>
        </div>
      </div>
      <!-- SERVICE FILTER  -->
      <ProductInfoServiceModal :is-open="openModal" @on-modal-close="closeServiceModal" />

      <!-- INFECTIONS FILTER -->
      <div v-if="indexOf === 'infection'" class="flex flex-col -mt-5 md:mt-0 md:space-y-6">
        <FilterInfection :infections="items"> </FilterInfection>
      </div>

      <!-- COLLECTIONS FILTER  -->
      <div v-if="['collection', 'test_type', 'service'].includes(indexOf)" class="flex flex-col space-y-6">

        <FilterCollectionMethods :Items="Items" :indexOf="indexOf"> </FilterCollectionMethods>
        <!-- <div
          v-for="collection in Items"
          :key="collection.title"
          class="items-center flex"
        >
          <ProductFilterOptions :itemCategory="collection" :indexOf="indexOf"></ProductFilterOptions>
        </div> -->

      </div>

      <button v-if="items.length > 6 && indexOf !== 'infection'" @click="viewMore"
        class="flex items-center px-7 space-x-4 mt-6">
        <span class="underline text-sm font-semibold">
          {{ viewMoreClicked ? $t("common.viewMore") : $t("common.viewLess") }}
        </span>
        <ChevronDownIcon v-if="viewMoreClicked" class="w-5 h-5" />
        <ChevronUpIcon v-else class="w-5 h-5" />
      </button>
    </div>
  </div>
</template>
<script setup>
import { useProductStore } from "@/stores/filter";
import {
ChevronDownIcon,
ChevronUpIcon
} from "@heroicons/vue/outline";
const filterStore = useProductStore();
const props = defineProps({
  indexOf: {
    type: String,
    required: true,
  },
  name: String,
  items: Array,
  modelValue: String,
  isActive: {
    type: Boolean,
    default: false,
  },
});
const showOptionFilter = ref(false);
const openModal = ref(false);
const searchValue = ref(null);
const ViewMoreVisible = ref(true);
const viewMoreClicked = ref(true);
const showSubItem = ref(false);


const Items = computed(() => {
  if (props.items.length > 5) ViewMoreVisible.value = false;
  if (viewMoreClicked.value && props.indexOf !== "infection")
    return props.items.slice(0, 5);
  else return props.items.slice();
});

onMounted(() => {
  showOptionFilter.value = props.isActive;
  showViewMoreButton();
});

// const filtredInfections = computed(() => {
//   let tempInfections = Items.value;

//   if (
//     props.indexOf === "infection" &&
//     typeof searchValue.value === "string" &&
//     searchValue.value.length > 0
//   ) {
//     tempInfections = tempInfections.filter((item) => {
//       return item.title.toUpperCase().includes(searchValue.value.toUpperCase());
//     });
//   }
//   return tempInfections;
// });

const selectedSort = (item) => {
  filterStore.setSortOptionMobile(item);
};

const showViewMoreButton = () => {
  ViewMoreVisible.value = false;
  if (props.items.length < 3) {
    ViewMoreVisible.value = true;
  }
  return showViewMoreButton.value;
};

const open = () => {
  showOptionFilter.value = !showOptionFilter.value;
};
const openServiceModal = () => {
  props.isActive = true;
  openModal.value = true;
};
const closeServiceModal = () => {
  openModal.value = false;
};
function viewMore() {
  viewMoreClicked.value = !viewMoreClicked.value;
}

function onTabChange(value) {
  filterStore.setInfectionSortType(value ? "AND" : "OR");
}
</script>
<style>
.infection-items {
  height: 330px !important;
  max-height: fit-content;
  overflow-y: auto !important;
}

.infection-items::-webkit-scrollbar {
  width: 6px;
}

.infection-items::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(161, 161, 161, 0.3) !important;
  border-radius: 10px !important;
}

.infection-items::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #00a1a1;
}
</style>
