<template>
  <div :class="{ 'border-t-2 border-gray-200': !isPanel }" class="px-4 py-5">
    <span v-if="!isPanel" class="text-[18px] font-semibold">
      {{ $t("filter.priceRange") }}
    </span>
    <div :class="isPanel ? 'mt-5' : 'mt-16'" class="mx-0 px-8 md:px-0 md:mx-4">
      <Slider
        :format="format"
        @input="handleSliderChange"
        v-model="sliderValue"
        :min="min"
        :max="max"
        :classes="{
          target:
            'relative box-border select-none touch-none tap-highlight-transparent touch-callout-none disabled:cursor-not-allowed',
          focused: 'slider-focused',
          tooltipFocus: 'slider-tooltip-focus',
          tooltipDrag: 'slider-tooltip-drag',
          ltr: 'slider-ltr',
          rtl: 'slider-rtl',
          horizontal: 'slider-horizontal h-1.5',
          vertical: 'slider-vertical w-1.5 h-80',
          textDirectionRtl: 'slider-txt-rtl',
          textDirectionLtr: 'slider-txt-ltr',
          base: 'w-full h-1 relative z-1 bg-gray-200 rounded',
          connects: 'w-full h-full relative overflow-hidden z-0 rounded',
          connect:
            'absolute z-1 top-0 right-0 transform-origin-0 transform-style-flat h-full w-full bg-primary cursor-pointer tap:duration-300 tap:transition-transform disabled:bg-gray-400 disabled:cursor-not-allowed',
          origin:
            'slider-origin absolute z-1 top-0 right-0 transform-origin-0 transform-style-flat h-full w-full h:h-0 v:-top-full txt-rtl-h:left-0 txt-rtl-h:right-auto v:w-0 tap:duration-300 tap:transition-transform',
          handle:
            'absolute rounded-full bg-primary border-0 shadow-slider cursor-grab focus:outline-none h:w-4 h:h-4 h:-top-1.5 h:-right-2 txt-rtl-h:-left-2 txt-rtl-h:right-auto v:w-4 v:h-4 v:-top-2 v:-right-1.25 disabled:cursor-not-allowed focus:ring focus:ring-green-500 focus:ring-opacity-30',
          handleLower: 'slider-hande-lower',
          handleUpper: 'slider-hande-upper',
          touchArea: 'h-full w-full',
          tooltip:
            'absolute block text-sm font-semibold whitespace-nowrap py-1 px-1.5 min-w-5 text-center text-white rounded   bg-primary transform h:-translate-x-1/2 h:left-1/2 v:-translate-y-1/2 v:top-1/2',
          tooltipTop:
            'bottom-6 h:arrow-bottom merge-h:bottom-3.5 border-primary',
          tooltipBottom:
            'top-6 h:arrow-top merge-h:top-5 border border-secondary',
          tooltipLeft: 'right-6 v:arrow-right merge-v:right-1',
          tooltipRight: 'left-6 v:arrow-left merge-v:left-7',
          tooltipHidden: 'slider-tooltip-hidden',
          active: 'slider-active shadow-slider-active cursor-grabbing',
          draggable: 'cursor-ew-resize v:cursor-ns-resize',
          tap: 'slider-state-tap',
          drag: 'slider-state-drag',
        }"
      />
    </div>
  </div>
</template>
<script setup>

import { useProductStore } from '@/stores/filter.js';
import { useGlobalStore } from '@/stores/global';
import Slider from '@vueform/slider';
const props = defineProps({
  min: Number,
  max: Number,
  isPanel: Boolean,
  isClear: Boolean
});
const sliderValue = ref()
const filterStore = useProductStore();
const globalStore = useGlobalStore();

const format = {
  suffix: '',
  prefix: '',
  decimals: 2,
};

onBeforeMount(() => {
  if (globalStore.siteCurrencySymbolPosition === 'after') {
    format.suffix = (globalStore.siteCurrencySymbolSpace) ? ' ' + globalStore.siteCurrencySymbol : globalStore.siteCurrencySymbol;
  } else {
    format.prefix = (globalStore.siteCurrencySymbolSpace) ? globalStore.siteCurrencySymbol + ' ' : globalStore.siteCurrencySymbol;
  }

  if (globalStore.siteCurrencyDecimal == false) {
    format.decimals = 0;
  }

  if (filterStore.min !== 0 && filterStore.max !== 0) {  
    sliderValue.value = [filterStore.min, filterStore.max];
  }
  else if (props.min !== 0 && props.max !== 0) {
    sliderValue.value = [props.min, props.max];
  }
});

const handleSliderChange = (newValue) => {
  const [newMin, newMax] = newValue;
  filterStore.setMinMaxPrice(newMin, newMax);
};



watch(() => filterStore.min, () => {
  if(props.min == filterStore.min && props.max == filterStore.max) {
    sliderValue.value = [filterStore.min, filterStore.max];
  }
});

watch(() => filterStore.max, () => {
  if(props.min == filterStore.min && props.max == filterStore.max) {
    sliderValue.value = [filterStore.min, filterStore.max];
  }
});


</script>
