<template>
  <div class="filter cursor-pointer">
    <div class="flex items-center">
   
      <input
        type="checkbox"
        v-model="checked"
        :value="itemCategory.slug"
        @change="selectedOptions(itemCategory)"
        :id="itemCategory.title"
        class="opacity-0 absolute w-5 h-5 peer cursor-pointer"
      />

      <ProductCheckbox></ProductCheckbox>
    
      <label
        :for="itemCategory.title"
        class="w-56 select-none text-[14px] cursor-pointer peer-checked:font-bold px-2"
        :class="itemCategory?.subcategories?.some((data)=>checked.includes(data.slug))?'font-bold':'font-normal'"
      >
        {{ itemCategory.title }}
      </label>
    </div>
  </div>
</template>
<script setup>
import {
  ChevronDownIcon,
  SearchIcon,
  ChevronUpIcon,
} from "@heroicons/vue/outline";

import { useProductStore } from "@/stores/filter";
const filterStore = useProductStore();
const props = defineProps({
  itemCategory: {
    type: Object,
  },
  indexOf: {
    type: String,
  },
  parentCategory: {
    type: Object,
  }

});

const checked = computed({
  get() {
    return filterStore.checked;
  },
  set() {
    return filterStore.checked;
  },
});

const selectedOptions = (item) => {

  filterStore.setChecked(item.slug);
  if (props.indexOf == "category") {
    filterStore.handleCategory(item)
  }else if(props.indexOf == "subcategory") {
    filterStore.handleSubcategorySelection(item, props.parentCategory);
  } else {
    filterStore.setCollection(item, props.indexOf);
  }
};


</script>
