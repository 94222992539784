<template>
  <div>
    <div
      class="flex max-w-40 lg:hidden"
      :class="!isBtnVisible ? 'fixed block bottom-5 z-50 left-3' : ''"
    >
      <button
        @click="$emit('clicked')"
        class="md:h-10 h-[50px] w-36 border bg-white border-secondary text-secondary text-sm font-bold flex items-center rounded-md relative p-2"
      >
        <div class="flex items-center gap-2">
          <img
            src="~/assets/icons/setup-preferences.svg"
            class="w-7 h-7"
            alt="filter icon"
            width="30"
            height="30"
          />

          {{ $t("filter.sort") }}
          <div
            v-if="selectedFilters.length !== 0"
            v-html="selectedFilters.length"
            class="bg-secondary w-5 h-5 rounded-full flex justify-center items-center text-white absolute -top-2 -right-1 text-[9px]"
          />
        </div>
      </button>
    </div>
    <div ref="btnRef"></div>
  </div>
</template>

<script setup>
import { useElementVisibility } from "@vueuse/core";

const props = defineProps({
  selectedFilters: {
    type: Object,
    default: {},
  },
});

const btnRef = ref(null);
const isBtnVisible = useElementVisibility(btnRef);
</script>
